import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
import DatePicker from 'vue2-datepicker';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import gstrTwoADownloadModal from './addgstrTwoADownload';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'gstrTwoADownload',
  watch: {
    currentPage: function() {
      this.getgstrTwoADownloadGridViewList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getgstrTwoADownloadGridViewList();
    }
  },
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker,
    gstrTwoADownloadModal
  },
  props: {},
  data() {
    return {
      parent_value_set_id: null,
      showValueSetModal: false,
      payload: null,
      showChildModal: false,
      unsubscribe: null,
      periodStartDate: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      defaultValue: {
        value: null,
        text: null
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      searchForm: {
        year_from: null,
        month_from: null,
        year_to: null,
        month_to: null
      },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedGSTNType: {
        value: null,
        text: null
      },
      selectedITCType: {
        value: null,
        text: null
      },
      legalEntityList: [],
      GSTNTypeList: [
        {
          value: 'GST',
          text: 'GST'
        },
        {
          value: 'ISD',
          text: 'ISD'
        }
      ],
      ITCTypeList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        },
        {
          value: 'GSTR1',
          text: 'GSTR1'
        },
        {
          value: 'GSTR3B',
          text: 'GSTR3B'
        },
        {
          value: '9A',
          text: 'GSTR9'
        },
        {
          value: 'IMS',
          text: 'IMS'
        },
        {
          value: '9C',
          text: 'GSTR9C'
        }
      ],
      gstrTwoADownloaddata: [],
      fields: [
        {
          key: 'gst_number',
          label: 'GST Number'
        },
        {
          key: 'username'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'request_id',
          class: 'd-none'
        },
        {
          key: 'gst_credential_id',
          class: 'd-none'
        },
        {
          key: 'Get_OTP',
          class: ''
        },
        {
          key: 'put_OTP',
          class: ''
        },
        {
          key: 'validate_OTP',
          label: 'Validate OTP & Sync'
        },

        {
          key: 'refresh',
          class: 'col-fix'
        },
        {
          key: 'status',
          class: 'val-fix'
        },
        {
          key: 'from_year',
          class: 'date-fix'
        },
        {
          key: 'from_month',
          class: 'date-fix'
        },
        {
          key: 'to_year',
          class: 'date-fix'
        },
        {
          key: 'to_month',
          class: 'date-fix'
        },
        {
          key: 'message'
        },
        {
          key: 'auth_token'
        },
        {
          key: 'le_name'
        },
        {
          key: 'gstn_type',
          label: 'GSTN Type'
        },
        {
          key: 'itc_type',
          label: 'Sync GSTR2A/GSTR6',
          class: 'sync-fix'
        }
      ]
    };
  },
  validations: {
    selectedLegalEntity: {
      text: {
        required
      }
    },
    selectedGSTNType: {
      text: {
        required
      }
    },
    selectedITCType: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.getLegalEntity();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
        }
        if (actionName === 'download' && !this.showChildModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'gstrTwoADownload/getGstrTwoADownloadList',
            'gstr-two-a-download',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getgstrTwoADownloadGridViewList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          gstn_type: this.selectedGSTNType.value,
          itc_type: this.selectedITCType.value,
          gst_number: this.selectedGSTNumber.value
        };
        this.loader = true;
        this.$store
          .dispatch('gstrTwoADownload/getGstrTwoADownloadList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.gstrTwoADownloaddata = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.gstrTwoADownloaddata.map(ele=>{
                ele.butnFlag = false;
                ele.syncBtnFlag = false;
              })
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getLegalEntity() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;

      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    getSetDefaultGridViewList() {
      this.gstrTwoADownloaddata = this.gstrTwoADownloaddata.map(data => {
        data.from_year = this.searchForm.year_from;
        return data;
      });
      this.gstrTwoADownloaddata = this.gstrTwoADownloaddata.map(data => {
        data.from_month = this.searchForm.month_from;
        return data;
      });
      this.gstrTwoADownloaddata = this.gstrTwoADownloaddata.map(data => {
        data.to_year = this.searchForm.year_to;
        return data;
      });
      this.gstrTwoADownloaddata = this.gstrTwoADownloaddata.map(data => {
        data.to_month = this.searchForm.month_to;
        return data;
      });
    },
    clear() {
      this.selectedLegalEntity = this.defaultValue;
      this.selectedGSTNType = this.defaultValue;
      this.selectedITCType = this.defaultValue;
      this.selectedGSTNumber = this.defaultValue;
      this.searchForm.year_from = null;
      this.searchForm.month_from = null;
      this.searchForm.year_to = null;
      this.searchForm.month_to = null;
      this.gstrTwoADownloaddata = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    getOtp(item, index) {
      const gstrTableIndex = index;
      const payload = {
        le_id: item.le_id,
        gst_credential_id: item.gst_credential_id,
        gstn_type: item.gstn_type,
        gst_number: item.gst_number,
        username: item.username
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/getOtpForGstrDownload', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            const results = response.data.data;
            this.gstrTwoADownloaddata[gstrTableIndex].message = results.message;
            this.gstrTwoADownloaddata[gstrTableIndex].status = results.status;
            this.gstrTwoADownloaddata[gstrTableIndex].request_id =
              results.request_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateOtp(item, index) {
      const gstrTableIndex = index;
      if (
        item.le_id &&
        item.gstn_type &&
        item.itc_type &&
        item.put_OTP &&
        item.from_year &&
        item.to_year &&
        item.from_month &&
        item.to_month
      ) {
      const payload = {
        le_id: item.le_id,
        gst_credential_id: item.gst_credential_id,
        gstn_type: item.gstn_type,
        gst_number: item.gst_number,
        itc_type: item.itc_type,
        username: item.username,
        received_otp: item.put_OTP,
        from_year: item.from_year,
        to_year: item.to_year,
        from_month: item.from_month,
        to_month: item.to_month
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/getValidateOtpForGstrDownload', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            const results = response.data.data;
            this.gstrTwoADownloaddata[gstrTableIndex].message = results.message;
            this.gstrTwoADownloaddata[gstrTableIndex].status = results.status;
            this.gstrTwoADownloaddata[gstrTableIndex].request_id =results.request_id;
            this.gstrTwoADownloaddata[gstrTableIndex].butnFlag=true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      } else {
        alert("Please fill in all mandatory fields!")
      }
    },
    validateYear(item, ty) {
      var fromYear = item.from_year;
      var toYear = item.to_year;
      if (fromYear == undefined) {
        alert('Please select from Year');
        return false;
      }
      if (toYear == undefined) {
        toYear = 9999;
      }
      if (ty == 'from') {
        if (fromYear > toYear) {
          alert('From year should be smaller then or equal to toYear');
          item.from_year = null;
          return false;
        }
      } else if (ty == 'to') {
        if (fromYear > toYear) {
          alert('From year should be smaller then or equal to toYear');
          item.to_year = null;
          return false;
        }
      }
    },
    validateMonth() {},
    refreshGstrList(item, index) {
      const gstrTableIndex = index;
      const payload = item.gst_credential_id;
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/getRefreshForGstrById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.gstrTwoADownloaddata[gstrTableIndex].message = results.message;
            this.gstrTwoADownloaddata[gstrTableIndex].status = results.status;
            this.gstrTwoADownloaddata[gstrTableIndex].request_id =
              results.request_id;
            this.gstrTwoADownloaddata[gstrTableIndex].auth_token =
              results.auth_token;
            if(this.gstrTwoADownloaddata[gstrTableIndex].status!="Pending"){
              this.gstrTwoADownloaddata[gstrTableIndex].butnFlag = false;
              this.gstrTwoADownloaddata[gstrTableIndex].syncBtnFlag = false;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    syncGstr(item, index) {
      const gstrTableIndex = index;
      const payload = {
        le_id: item.le_id,
        gst_credential_id: item.gst_credential_id,
        gstn_type: item.gstn_type,
        itc_type: item.itc_type,
        gst_number: item.gst_number,
        username: item.username,
        from_year: item.from_year,
        to_year: item.to_year,
        from_month: item.from_month,
        to_month: item.to_month
      };
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/getSyncGstrDownload', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            const results = response.data.data;
            this.gstrTwoADownloaddata[gstrTableIndex].message = results.message;
            this.gstrTwoADownloaddata[gstrTableIndex].status = results.status;
            this.gstrTwoADownloaddata[gstrTableIndex].request_id =
              results.request_id;
            this.gstrTwoADownloaddata[gstrTableIndex].syncBtnFlag = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    hideChildModal() {},
    rowSelected() {},
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedLegalEntity.value) {
        this.selectedLegalEntity = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
